import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import Layout from '../components/layout';
import SEO from '../components/seo';

import primeLogo from '../../static/logo.png';

const PageWrapper = styled.div`
  max-width: 1440px;
  margin: auto;
  margin-top: 89px;
  padding: 20px 120px;

  .subtitle {
    font-weight: 600;
  }
`;

const PrivacyPolicyPage = ({ location }) => (
  <Layout source="Privacy Policy Page">
    <SEO
      title="Privacy Policy"
      description="Our site's Privacy Policy."
      meta={[
        {
          property: 'og:image',
          content: primeLogo,
        },
        {
          property: 'og:url',
          content: location.href,
        },
        {
          name: 'keywords',
          content:
            'Commercial Real Estate Service Provider in the Philippines, Commercial Real Estate Service Firm in the Philippines, Commercial Real Estate Leasing Firm  in the Philippines, Commercial Real Estate Advisory Firm in the Philippines, Commercial Real Estate Consultancy Firm in the Philippines, The  Leading Commercial Real Estate Consultancy Firm in the Philippines, The Leading Commercial Real Estate Leasing Agency in the Philippines, The Leading Commercial Real Estate Advisory  Firm in the philippines, The Leading Commercial Real Estate Service Firm  in the Philippines, The Leading Commercial Real Estate Service Provider  in the Philippines, Industrial Leasing Solutions in the Philippines, Warehouse Leasing Solutions in the Philippines, Real Estate Leasing Solutions in the Philippines, Retail and Office Leasing Solutions in the Philippines, Retail Leasing Solutions in the Philippines, Real Estate Brokerage Leasing Solutions in the Philippines, Real Estate Research and Advisory Solutions in the Philippines, Property Research and Advisory Solutions in the Philippines, Property and Facilities Management Solutions in the Philippines, Property and Facilities Management Solutions in the Philippines, Property and Facilities Management Solutions in the Philippines, Industrial Warehouse leasing, Office Leasing Solution, Retail Leasing Solution, Real Estate Research and Advisory Solutions, Real Estate Brokerage Leasing, Real Estate Leasing Solutions, Property Leasing Services',
        },
      ]}
    />
    <PageWrapper>
      <Row className="main-row" type="flex" justify="center">
        <Col xs={23} sm={23} md={23} lg={23}>
          <h2>Privacy Policy</h2>
          <h4>AUGUST 2020</h4>
          <p className="text">
            This privacy policy has been compiled to better serve those who are
            concerned with how their personal information is being used online.
            Please read our privacy policy carefully to get a clear
            understanding of how we collect, use, protect or otherwise handle
            your personal information in accordance with our website.
          </p>
          <p className="subtitle">
            What personal information do we collect from the people that visit
            our blog, website or app?
          </p>
          <p className="text">
            When ordering or registering on our site, as appropriate, you may be
            asked to enter your name, email address, phone number or other
            details to help you with your experience.
          </p>
          <p className="subtitle">When do we collect information?</p>
          <p className="text">
            We collect information from you when you subscribe to a newsletter,
            fill out a form or enter information on our site.
          </p>
          <p className="subtitle">How do we use your information?</p>
          <span className="text">
            We may use the information we collect from you when you register,
            make a subscription, sign up for our newsletter, respond to a survey
            or marketing communication, surf the website, or use certain other
            site features in the following ways:
            <ul className="list">
              <li>To improve our website in order to better serve you.</li>
              <li>
                To allow us to better service you in responding to your customer
                service requests.
              </li>
              <li>
                To send periodic emails regarding your order or other products
                and services.
              </li>
              <li>
                To follow up with them after correspondence (live chat, email or
                phone inquiries)
              </li>
            </ul>
          </span>
          <p className="subtitle">How do we protect your information?</p>
          <p className="text">
            Our website is scanned on a regular basis for security holes and
            known vulnerabilities in order to make your visit to our site as
            safe as possible.
          </p>
          <p className="text">
            Your personal information is contained behind secured networks and
            is only accessible by a limited number of persons who have special
            access rights to such systems, and are required to keep the
            information confidential. In addition, all sensitive/credit
            information you supply is encrypted via Secure Socket Layer (SSL)
            technology.
          </p>
          <p className="text">
            All transactions are processed through a gateway provider and are
            not stored or processed on our servers.
          </p>
          <p className="subtitle">Do we use &apos;cookies&apos;?</p>
          <p className="text">We do not use cookies for tracking purposes.</p>
          <p className="text">
            You can choose to have your computer warn you each time a cookie is
            being sent, or you can choose to turn off all cookies. You do this
            through your browser settings. Since every browser is a little
            different, look at your browser&apos;s Help Menu to learn the
            correct way to modify your cookies.
          </p>
          <p className="text">
            If you turn cookies off, some of the features that make your site
            experience more efficient may not function properly.
          </p>
          <p className="subtitle">Third-party disclosure</p>
          <p className="text">
            We do not sell, trade, or otherwise transfer to outside parties your
            Personally Identifiable Information unless we provide users with
            advance notice. This does not include website hosting partners and
            other parties who assist us in operating our website, conducting our
            business, or serving our users, so long as those parties agree to
            keep this information confidential. We may also release information
            when it&apos;s release is appropriate to comply with the law,
            enforce our site policies, or protect ours or others&apos; rights,
            property or safety.
          </p>
          <p className="text">
            However, non-personally identifiable visitor information may be
            provided to other parties for marketing, advertising, or other uses.
          </p>
          <p className="subtitle">Third-party links</p>
          <p className="text">
            We do not include or offer third-party products or services on our
            website.
          </p>
          <p className="subtitle">Contacting Us</p>
          <p className="text">
            If there are any questions regarding this privacy policy, you may
            contact us using the information below.
          </p>
          <p className="text-no-margin">primephilippines.com</p>
          <p className="text-no-margin">
            Ground Floor, Pacific Century Tower, Quezon Avenue Quezon City
          </p>
          <p className="text-no-margin">Philippines</p>
          <p className="text-no-margin">inquiry@primephilippines.com</p>
        </Col>
      </Row>
    </PageWrapper>
  </Layout>
);

export default PrivacyPolicyPage;
